import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ogImage from "../images/OG_Image.png";

const PrivacyPolicy = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      ogImage={ogImage}
      keywords={["Flowspring", "privacy policy"]}
      description="Flowspring's privacy policy"
    />
    <div className="content">
      <h2>Flowspring&apos;s Privacy Policy</h2>
      <p>
        Flowspring, Inc. (“Flowspring” or “we”) is committed to safeguarding
        your privacy. This Privacy Policy explains the information we collect,
        how we collect it, and how we safeguard your Personal Information
        (defined below). Please read Flowspring’s Terms and Conditions
        attached to your Subscription Services Agreement and Order Form (the
        “Terms and Conditions”) for more information on terms and conditions
        governing your use of the Flowspring Services and Software. Please
        direct any questions regarding this policy to support@flowspring.com.
      </p>

      <h3>Information We Collect</h3>
      <p>
        We collect several types of information from and about users of our
        Services and Software, including information:
        <ul>
          <li>
            by which you may be personally identified, such as name, postal
            address, email address, telephone number, etc. (collectively,
            &quot;Personal Information&quot;); and
          </li>
          <li>
            about your internet connection, the equipment you use to access
            our website and usage details (“usage information”).
          </li>
        </ul>
        We collect this information:
        <ul>
          <li>directly from you when you provide it to us; and</li>
          <li>
            automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses,
            and information collected through cookies, web beacons, and other
            tracking technologies.
          </li>
        </ul>
        <b>Cookies.</b>
        {" "}
        Flowspring may log information using cookies, beacons,
        tags, and scripts. Cookies are small data files stored on your hard
        drive by a website. We may use both session Cookies (which expire once
        you close your web browser) and persistent Cookies (which stay on your
        computer until you delete them) to provide you with a more personal
        and interactive experience on our website. These technologies are used
        in analyzing trends, administering the Site and services, tracking
        users’ movements around the website and to gather demographic
        information about our user base as a whole. We may receive reports
        based on the use of these technologies by our analytics or service
        provider companies on an individual as well as aggregated basis. This
        type of information is collected to make the website more useful to
        you and to tailor the experience with us to meet your special
        interests and needs.
      </p>

      <h3>How We Use the Information We Collect</h3>
      <p>
        We use the information we collect to provide, maintain, protect and
        improve Flowspring, to develop new features and products, and to
        protect Flowspring and our users. We also use this information to
        offer you tailored content. When you contact Flowspring, we keep a
        record of your communication to help solve any issues you might be
        facing. We may use your email address to inform you about our
        services, such as letting you know about upcoming changes or
        improvements.
      </p>

      <h3>Information We Share</h3>
      <p>
        We may share non-personally identifiable information publicly and with
        other organizations. However, we do not share Personal Information
        with organizations outside of Flowspring except for legal reasons, as
        detailed below. We will share Personal Information with companies,
        organizations or individuals outside of Flowspring if we have a good
        faith belief that access, use, preservation or disclosure of the
        information is reasonably necessary to:
        <li>
          meet any applicable law, regulation, legal process or enforceable
          governmental request;
        </li>
        <li>
          enforce the applicable Terms and Conditions, including investigation
          of potential violations;
        </li>
        <li>
          detect, prevent, or otherwise address fraud, security or technical
          issues; or
        </li>
        <li>
          protect against harm to the rights, property or safety of
          Flowspring, our users or the public as required or permitted by law.
        </li>
      </p>

      <h3>Information Security</h3>
      <p>
        Flowspring takes commercially reasonable precautions to protect
        Personal Information. We use a variety of industry-standard technical,
        contractual, administrative and physical security measures and
        procedures to help protect your personal information from unauthorized
        access, use, alteration or disclosure. Please note that despite our
        best efforts, no one can guarantee the security of personal
        information. Unauthorized entry or use, hardware or software failure,
        and other factors may compromise the security of personal information
        at any time. We cannot and do not ensure or warrant the security of
        any information you transmit and you do so at your own risk.
        Flowspring operates secure data networks protected by
        industry-standard firewall and password protection systems.
      </p>

      <h3>Links to Other Websites</h3>
      <p>
        Our websites may provide links to other websites for your convenience
        and information. These websites may operate independently from
        Flowspring. Linked websites may have their own privacy notices or
        policies, which we strongly suggest you review if you visit any linked
        websites. To the extent any linked websites you visit are not owned or
        controlled by Flowspring, we are not responsible for the websites’
        content, any use of the websites, or the privacy practices of the
        websites.
      </p>

      <h3>Use of Site by Minors</h3>
      <p>
        Minors under the age of 18 are prohibited from using the eVestment
        site. We request that these individuals neither use the eVestment site
        nor provide Personal Information through the eVestment Site.
      </p>

      <h3>Changes to Our Privacy Policy</h3>
      <p>
        This Privacy Policy may be amended from time to time. Please review it
        periodically. Changes to this Privacy Policy will be effective upon
        posting to the eVestment site. Your use of the eVestment site
        following these changes means that you accept the revised policy.
      </p>
    </div>
  </Layout>
);

export default PrivacyPolicy;
